import React, { useEffect, useContext, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { Link, navigate } from "gatsby"

import states from "../data/states"
import jiggswapApi from "../api/jiggswap"
import { AuthContext } from "../context/AuthContext"
import { MapApiErrors } from "../helpers/ErrorParser"
import JiggswapInput from "../components/forms/JiggswapInput"
import JiggswapSelectList from "../components/forms/JiggswapSelectList"

import JiggswapImageUpload from "../components/forms/JiggswapImageUpload"

const UpdateProfilePage = () => {
  const {
    state: { username, unloaded },
  } = useContext(AuthContext)

  const { register, handleSubmit, errors, setError, setValue } = useForm()

  const imageBlobState = useState(null)
  const [imageBlob, setImageBlob] = imageBlobState
  const [imageUpdated, setImageUpdated] = useState(false)

  useEffect(() => {
    const buildImageBlob = async (imageUrl) => {
      var img = new Image()
      img.setAttribute("crossorigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob((blob) => setImageBlob(blob))
      }

      img.src = imageUrl
    }

    if (!unloaded) {
      const fetchData = async () => {
        var response = await jiggswapApi.get(`/userprofile`)

        Object.entries(response.data).forEach(([key, value]) => setValue(key, value))

        buildImageBlob(response.data.imageCdnUrl)
      }

      fetchData()
    }
  }, [setValue, username, unloaded, setImageBlob])

  const submitForm = async (values) => {
    try {
      var formData = new FormData()

      for (var key in values) {
        formData.append(key, values[key])
      }

      if (imageUpdated && imageBlob) {
        formData.set("imageBlob", imageBlob, "image")
      }

      await jiggswapApi.put(`/userprofile`, formData)

      navigate(`/user?username=${username}`)
    } catch (err) {
      var apiErrors = MapApiErrors(err.response.data.errors)

      setError(apiErrors)
    }
  }

  return (
    <>
      <Helmet title="My Profile - JiggSwap" />

      <div className="site-content">
        <div className="is-size-2">Update My Profile</div>

        <hr />

        <form onSubmit={handleSubmit(submitForm)}>
          <JiggswapImageUpload
            setUpdated={setImageUpdated}
            blobState={imageBlobState}
            imageSize={{ width: 256, height: 256 }}
            previewClass="image is-128x128"
          />

          <JiggswapInput name="firstName" label="First Name" type="text" register={register} errors={errors} />

          <JiggswapInput name="lastName" label="Last Name (or Initial)" type="text" register={register} errors={errors} />

          <JiggswapInput name="streetAddress" label="Street Address" type="text" register={register} errors={errors} />

          <JiggswapInput name="city" type="text" register={register} errors={errors} />

          <JiggswapSelectList name="state" options={states} register={register} errors={errors} />

          <JiggswapInput name="zip" label="Zip Code" type="text" register={register} errors={errors} />

          <div className="buttons">
            <button type="submit" className="button is-primary">
              Update Profile
            </button>
            <Link to={`/user?username=${username}`} className="button">
              Go Back
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default UpdateProfilePage
